/**
 * Styles for forms including layout for two column forms
 */
.formGrid {
  @include ms-Grid;
  margin: 0 -8px;

  @media screen and (min-width: $ms-screen-min-xl) {
    overflow: hidden;
  }

  .formGrid-row {
    @include ms-Grid-row;

    .formNav {
      @include ms-Grid-col;
      @include ms-lg12;
      @include ms-xl3;
      @include ms-xxl2;
      min-width: 330px;

      .h2 {
        @media screen and (min-width: $ms-screen-max-lg) {
          text-align: right;
          margin-top: 80px !important;
        }
      }

      @media screen and (max-width: $ms-screen-max-md) {
        float: none !important;
      }

      @media screen and (max-width: $ms-screen-min-md) {
        display: none;
      }

      .ms-Button.ms-Pivot-link {
        @include ms-fontWeight-regular;
        @include ms-fontSize-l;
        color: $ms-color-neutralPrimary;
        background-color: transparent;
        padding: 0 8px 0 0;

        .ms-Button-flexContainer {
          justify-content: flex-end;
          line-height: 25px;

          .ms-Pivot-linkContent {
            display: inline-block;
            line-height: 1.5;
            padding-left: 24px;
            border-left: 2px solid transparent;
          }
        }

        @media screen and (min-width: $ms-screen-max-lg),
          (max-width: $ms-screen-max-sm) {
          font-weight: 300;
          font-size: 18px;
          width: 100%;
          display: block;
          padding: 0;
          margin-bottom: 36px;
          height: auto;
        }

        &:before {
          border: none;
        }

        &.is-selected {
          font-weight: 600;
          color: $ms-color-neutralPrimary;
          border-bottom: none;

          .ms-Button-flexContainer {
            .ms-Pivot-linkContent {
              border-color: $ms-color-neutralPrimary;
            }
          }
        }
      }
    }

    .formBody {
      @include ms-Grid-col;
      @include ms-lg12;
      @include ms-xl7;
      @include ms-xxl6;
      padding-top: 30px;

      & > div {
        padding: 30px 40px 40px;
        background-color: $ms-color-white;
      }

      @media screen and (min-width: $ms-screen-min-xl) {
        padding-top: 50px;
        padding-left: 40px;
      }

      @media screen and (max-width: $ms-screen-max-md) {
        float: none !important;
      }
    }
  }

  .createCommunity-row {
    @media screen and (min-width: $ms-screen-max-lg) {
      display: flex;

      .formNav {
        .ms-FocusZone {
          flex-direction: column;
          display: flex;

          .ms-Button.ms-Pivot-link {
            align-self: flex-end;
            margin-bottom: 35px;
            margin-right: 0;
            max-width: 282px;

            .ms-Button-flexContainer {
              display: block;
              text-align: right;
              line-height: 25px;
            }

          }
          .ms-Button.ms-Pivot-link:before {
            height: 0;
          }
        }
      }
    }

    .ms-Pivot-linkContent {
      text-align: right;
    }
    .formBody {
      width: 90%;
      max-width: 850px;
    }

    @media screen and (max-width: $ms-screen-min-xl) {
      .formBody {
        width: 100%;
        max-width: 100%;
      }
    }
  }
}

.formGrid-Registration {
    @include ms-Grid;
    margin: 0 -8px;

    @media screen and (min-width: $ms-screen-min-xl) {
        overflow: hidden;
    }

    .formGrid-row-Registration {
        @include ms-Grid-row;

        .formNav-Registration {
            @include ms-Grid-col;
            @include ms-lg12;
            @include ms-xl3;
            @include ms-xxl2;
            min-width: 330px;

            .h2 {
                @media screen and (min-width: $ms-screen-max-lg) {
                    text-align: right;
                    margin-top: 80px !important;
                }
            }

            @media screen and (max-width: $ms-screen-max-md) {
                float: none !important;
            }


            .ms-Button.ms-Pivot-link {
                @include ms-fontWeight-regular;
                @include ms-fontSize-l;
                color: $ms-color-neutralPrimary;
                background-color: transparent;
                padding: 0 8px 0 0;

                .ms-Button-flexContainer {
                    justify-content: flex-end;
                    line-height: 25px;

                    .ms-Pivot-linkContent {
                        display: inline-block;
                        line-height: 1.5;
                        padding-left: 24px;
                        border-left: 2px solid transparent;
                    }
                }

                @media screen and (min-width: $ms-screen-max-lg), (max-width: $ms-screen-max-sm) {
                    font-weight: 300;
                    font-size: 18px;
                    width: 100%;
                    display: block;
                    padding: 0;
                    margin-bottom: 36px;
                    height: auto;
                }

                &:before {
                    border: none;
                }

                &.is-selected {
                    font-weight: 600;
                    color: $ms-color-neutralPrimary;
                    border-bottom: none;

                    .ms-Button-flexContainer {
                        .ms-Pivot-linkContent {
                            border-color: $ms-color-neutralPrimary;
                        }
                    }
                }
            }
        }

        .formBody-Registration {
            @include ms-Grid-col;
            @include ms-lg12;
            @include ms-xl7;
            @include ms-xxl6;
            padding-top: 30px;

            & > div {
                padding: 30px 40px 40px;
                background-color: $ms-color-white;
            }

            @media screen and (min-width: $ms-screen-min-xl) {
                padding-top: 50px;
                padding-left: 40px;
            }

            @media screen and (max-width: $ms-screen-max-md) {
                float: none !important;
            }
        }
    }
}

.govTeams-actionButton {
  margin-top: 10px;
  margin-bottom: 10px;
}

.formFields {
  .formFields-col2 {
    @include ms-clearfix;
    margin-left: -5px;
    margin-right: -5px;

    .formField {
      width: 50%;
      float: left;
      padding: 0 5px;
    }
  }

  .formField {
    margin-bottom: 10px;

    h2 {
      margin-bottom: 0;

      & + p {
        margin-top: 10px;
      }
    }

    .formLabel {
      display: inline-block;

      &[required]:after {
        padding-right: 0;
      }

      & + i {
        color: $ms-color-themePrimary;
        padding-left: 12px;
      }
    }

    .govTeams-messageBar {
      margin-bottom: 20px;
    }
  }

  .formField-toggles {
    margin: 30px 0 40px;
  }

  .formField-toggleGroup {
    border-top: 1px solid $ms-color-neutralLight;
    display: flex;
    padding: 15px 20px 15px 0;
    align-items: center;

    &:last-child {
      border-bottom: 1px solid $ms-color-neutralLight;
    }

    .formField-toggleGroup--label {
      display: flex;
      align-items: center;
      flex: 3;

      @media screen and (max-width: $ms-screen-max-md) {
        flex: 6;
      }

      .ms-Label {
        display: inline-block;
      }
    }

    i {
      font-size: 19px;
      line-height: 1em;
      width: 40px;
      height: 40px;
      background-color: $ms-color-white;
      box-shadow: 0px 2px 6px 1px rgba(0, 0, 0, .2);
      border-radius: 50%;
      margin: 0 10px 0 0;
      padding: 11px;
    }

    .ms-Label {
      @include ms-fontSize-mPlus;
    }

    .ms-Toggle {
      flex: 4;
      margin-bottom: 0;
    }
  }

  .formField-text {
    line-height: 30px;
    padding-right: 12px;
    padding-bottom: 0px;
    padding-left: 12px;
    box-sizing: border-box;
    font-size: 14px;
    height: 32px;
    display: flex;
    flex-direction: row;
    align-items: stretch;
    position: relative;
    border-width: 1px;
    border-style: solid;
    border-color: rgb(166, 166, 166);
    user-select: none;

    &.readOnly {
      color: $ms-color-neutralSecondary;
    }
  }
}

.formFooter {
  @include ms-clearfix;
  margin-top: 20px;
  margin-bottom: 20px;

  &:last-child {
    margin-bottom: 0;
  }

  .formFooter-left {
    .ms-Button:last-child {
      margin-left: 10px;
    }

    @media screen and (min-width: $ms-screen-min-md) {
      float: left;
    }
  }

  .formFooter-right {
    margin-top: 20px;

    @media screen and (min-width: $ms-screen-min-md) {
      margin-top: 0;
      float: right;
    }
  }
}

.form-TnC {
  .ms-TextField-fieldGroup {
    border: none;
    margin-bottom: 20px;

    .ms-TextField-field {
      background-color: $ms-color-neutralLighterAlt;
      padding: 20px !important;
    }
  }

  .ms-Checkbox {
    width: 100%;
  }
}

.formBasic {
  font-size: $ms-font-size-m-plus;

  .formFields {
    margin-top: 30px;
  }

  .formGrid-row {
    .formBody {
      padding-top: 0;
      padding-left: 8px;
    }
  }

  .ms-Pivot {
    @include ms-screenReaderOnly;
  }

  .ms-Button.ms-Pivot-link {
    @include ms-fontWeight-regular;
    color: $ms-color-neutralTertiary;
    background-color: transparent;
    padding: 0;

    &.is-selected {
      color: $ms-color-neutralPrimary;

      &:before {
        border-bottom: none;
      }
    }

    i {
      font-size: .6em;
      margin: 0 1em;
    }
  }

  .ms-MessageBar {
    .ms-MessageBar-icon i {
      font-size: $ms-font-size-xxl;
    }

    .ms-MessageBar-text p {
      font-size: $ms-font-size-m-plus;
      line-height: 1.4;
    }
  }
}

.formField-error {
  .ms-TextField-fieldGroup {
    border-color: rgb(168, 0, 0);
  }

  .formField-errorMessage {
    font-size: 12px;
    color: rgb(168, 0, 0);
    padding-top: 5px;
  }
}

.form-textArea-container {
  height: 25em;
  border: 1px solid $ms-color-neutralTertiary;
  background-color: $ms-color-neutralLighterAlt;
  margin-bottom: 20px;
  overflow: auto;

  .form-textArea {
    background-color: $ms-color-white;
    padding: 30px;

    h2 {
      margin-top: 40px;
    }
  }
}

.heading-success,
.heading-error,
.heading-alert,
.heading-warning {
  margin-bottom: 16px;
}

div.heading-success,
div.heading-error,
div.heading-alert,
div.heading-warning {
  font-size: 21px;
}

li.heading-success:before,
li.heading-error:before,
li.heading-alert:before,
li.heading-warning:before {
  font-size: 1em;
  vertical-align: -.1em;
}

.heading-success:before,
.status-success:before {
  @include ms-Icon;
  @include ms-Icon--CompletedSolid;
  font-size: 2em;
  color: $aqua;
  margin-right: .25em;
  vertical-align: -.3em;
  display: inline-block;
}

.heading-error:before {
  @include ms-Icon;
  //@include ms-Icon--SkypeCircleMinus;
  @include ms-Icon--ErrorBadge;
  font-size: 2em;
  color: $ms-color-red;
  margin-right: .25em;
  vertical-align: -.3em;
  display: inline-block;
}

.heading-warning:before {
  @include ms-Icon;
  //@include ms-Icon--SkypeCircleMinus;
  @include ms-Icon--Warning;
  font-size: 2em;
  color: $ms-color-yellow;
  margin-right: .25em;
  vertical-align: -.3em;
  display: inline-block;
}

.heading-alert:before {
  @include ms-Icon;
  //@include ms-Icon--SkypeCircleMinus;
  @include ms-Icon--Info;
  font-size: 2em;
  //color: $ms-color-yellow;
  margin-right: .25em;
  vertical-align: -.3em;
  display: inline-block;
}

.actionStatus {
  vertical-align: top;
}

.status-error:before {
  @include ms-Icon;
  @include ms-Icon--StatusErrorFull;
  font-size: .9em;
  color: $ms-color-red;
  margin-right: .25em;
  vertical-align: -.3em;
  display: inline-block;
}

.tagPicker-container {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;

  .ms-Button {
    flex-shrink: 1;
    margin: 0 0 0 20px;
    height: 30px;
  }

  .ms-BasePicker {
    -webkit-box-flex: 1;
    flex-grow: 1;
  }
}

.communitySummary {
  .ms-Button--primary {
    margin-top: 30px;
  }
}

.govTeams-fieldVerify {
  width: 310px;
  display: inline-block;
  padding-right: 90px;
  position: relative;

  .ms-TextField-errorMessage {
    font-size: inherit !important;
  }

  .ms-Button--primary {
    position: absolute;
    top: 0;
    right: 0;
  }
}

.buttonSpacing {
  margin-left: 10px;
}

.field-validation-valid.text-danger {
  display: none;
}

.messageBox {
  background: #f3f2f1;
  padding: 16px;
  margin-bottom: 24px;
}

.leadText {
  font-size: 21px;
  font-weight: 300;
}

.passwordSection {
  background-color: #ccd0df;
  padding: 40px;
  margin-top: 40px;
  margin-bottom: 40px;
  margin-left: -40px;
  margin-right: -40px;
  
  h2 {
    font-size: 18px;
    font-weight: 600;
    color: $ms-color-neutralPrimary;
    margin-top: 0;
  }

  h3 {
    font-size: 18px;
    font-weight: 300;
  }

  ul {
    padding: 0 0 0 18px;
    margin-bottom: 24px;

    &:last-child {
      margin-bottom: 0;
    }
  }
}

.govTeams-registration {
  .progress {
    border: 1px solid $ms-color-neutralTertiary;
    background-color: transparent;
    background-image: none;
    box-shadow: none;

    .progress-bar {
      background-image: none;
      box-shadow: none;
    }
  }
}

.required-field::after {
  content: " *";
  color: rgb(164, 38, 44);
  padding-right: 12px;
}

.govTeams-modal {
  min-height: 300px;
}

.govTeams-cc-modal {
  min-height: 300px;
}

.formComponent {
  .formField {
    margin-bottom: 20px;

    .ms-TooltipHost {
      display: flex !important;
    }
  }
  .formFooter {
    margin-top: 30px;
    .formFooter-left {
      display: flex;
    }
  }
}