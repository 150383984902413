.govTeams-dialog {
    // Fix for a bug that for some reason shrinks Dialog boxes incorrectly.
    height: 100% !important;

    .ms-Dialog-main {
        text-align: center !important;

        @media (min-width: $ms-screen-min-lg) {
            min-width: 570px;
        }

        .ms-Dialog-header {
            .ms-Dialog-title {
                @extend .h2;
                color: $govTeams-blueDark;
                line-height: 1.2;
                margin-bottom: 0 !important;
                padding-top: 30px;
            }
        }

        .ms-Dialog-inner {
            @media screen and (min-width: $ms-screen-max-md) {
                padding-left: 50px;
                padding-right: 50px;
                padding-bottom: 50px;
            }

            ul {
                list-style-type: disc;
                margin: 10px 0 0 0;
                padding: 0;
                text-align: left;
            }

            li-Left {
                /*text-align: left !important;*/
                float: left; /* Add float left */
                margin: 10px 0;
            }

            p {
                @include ms-fontSize-l;
            }

            .ms-Checkbox {
                margin: 10px auto 30px;

                .ms-Checkbox-text {
                    white-space: nowrap;
                }
            }
        }

        .ms-Dialog-actions {
            margin-top: 30px;
        }

        .ms-Dialog-actionsRight {
            text-align: center !important;
        }
    }

    &.govTeams-onboarding {
        .govTeams-iconfont-badge {
            font-size: 64px;
            height: 85px;
            color: $ms-color-themeDark;
            margin-bottom: 30px;
        }

        .dialogTitle {
            @include ms-font-su;
            line-height: 1.1;
            margin-bottom: 20px;
        }

        .ms-Dialog-title {
            padding-top: 30px;
        }

        .ms-Dialog-main {
            .dialogText {
                @include ms-fontSize-l;
                @include ms-fontWeight-semilight;
                height: 2em;
            }

            @media (min-width: 768px) {
                min-width: 700px;
                // min-height: 460px;
            }

            @media (min-width: 1024px) {
                min-width: 900px;
                // min-height: 460px;
            }
        }

        .ms-Dialog-inner {
            padding-bottom: 0;
            padding-left: 0;

        }

        ul {
            list-style-type: disc;
            margin: 10px 0 0 0;
            padding: 0;
            text-align: left;
        }

        li-Left {
            /*text-align: left !important;*/
            float: left; /* Add float left */
            margin: 10px 0;
        }

        openCommunityLink {
            list-style-type: disc;
            margin: 10px 0 0 0;
            padding: 0;
            text-align: left;
        }

        .dialogSection {
            @media (min-width: 768px) {
                display: flex;
            }

            .dialogSection-text {
                text-align: left;

                @media (min-width: 768px) {
                    flex: 1;
                }

                h2 {
                    font-size: 32px;
                }
            }

            .dialogSection-image {
                @media (min-width: 768px) {
                    flex: 2;
                }

                img {
                    width: 100%;
                }
            }
        }

        .dialogFooter-top {
            @media (min-width: 768px) {
                display: flex;
                align-items: center;
                justify-content: flex-end;
            }
        }

        .nextButton-label {
            font-size: 16px;
            display: inline-block;
            margin-bottom: 15px;

            @media (min-width: 768px) {
                text-align: right;
                margin-right: 30px;
                margin-bottom: 0;
                white-space: nowrap;
            }
        }

        .ms-Dialog-actions {
            margin-top: 0;
        }

        .ms-Pivot {
            .ms-Pivot-link {
                color: $ms-color-neutralLight;
                font-size: 10px;
                height: auto;
                padding: 0;

                &:last-child {
                    margin-right: 0;
                }

                &.is-selected {
                    color: $ms-color-neutralPrimary;

                    &:before {
                        border-bottom-color: transparent;
                    }
                }
            }
        }

        .iconPersonalise {
            color: $ms-color-white;
            font-size: 48px;
            width: 110px;
            height: 110px;
            line-height: 110px;
            background: rgb(34, 145, 225);
            background: linear-gradient( 156deg, rgba(34, 145, 225, 0.9332107843137255) 35%, rgba(58, 117, 223, 1) 85% );
            border-radius: 50%;
            display: inline-block;
            margin-top: -15px;
            margin-bottom: 20px;
            position: relative;

            .iconEdit {
                color: $ms-color-themePrimary;
                font-size: 20px;
                width: 45px;
                height: 45px;
                line-height: 45px;
                background-color: $ms-color-neutralLighter;
                border-radius: 50%;
                position: absolute;
                top: -5px;
                right: -5px;
            }
        }

        .communityTypes {
            height: 85px;
            margin-bottom: 30px;
        }

        .iconCommunityType {
            background-color: $ms-color-neutralLighter;
            width: 80px;
            height: 65px;
            border-radius: 10px;
            box-shadow: 0px 2px 6px 1px rgba(0, 0, 0, 0.2);
            display: inline-block;
            margin-right: 20px;

            .iconHeader {
                width: 100%;
                height: 18px;
                border-top-left-radius: 10px;
                border-top-right-radius: 10px;
                position: relative;

                i {
                    color: $ms-color-white;
                    font-size: 18px;
                    line-height: 36px;
                    width: 36px;
                    height: 36px;
                    border-radius: 50%;
                    box-shadow: 0px 2px 6px 1px rgba(0, 0, 0, 0.2);
                    display: block;
                    position: absolute;
                    top: -9px;
                    right: -9px;
                }
            }

            .iconLabel {
                @include ms-fontSize-l;
                @include ms-fontWeight-semibold;
                margin-top: 10px;
            }

            &:last-child {
                margin-right: 0;
            }

            &.typeClosed {
                vertical-align: -8px;

                .iconHeader {
                    background-color: $community-closed;

                    i {
                        background-color: $community-closed;
                    }
                }

                .iconLabel {
                    color: $community-closed;
                }
            }

            &.typeOpen {
                .iconHeader {
                    background-color: $community-open;

                    i {
                        background-color: $community-open;
                    }
                }

                .iconLabel {
                    color: $community-open;
                }
            }

            &.typeHidden {
                vertical-align: -8px;

                .iconHeader {
                    background-color: $community-hidden;

                    i {
                        background-color: $community-hidden;
                    }
                }

                .iconLabel {
                    color: $community-hidden;
                }
            }
        }

        .interactIcons {
            margin-bottom: 30px;
        }

        .interactIcon {
            display: inline-block;
            margin-right: 20px;

            &:last-child {
                margin-right: 0;
            }

            i {
                font-size: 45px;
                line-height: 85px;
                width: 85px;
                height: 85px;
                background-color: $ms-color-white;
                box-shadow: 0px 2px 6px 1px rgba(0, 0, 0, 0.2);
                border-radius: 50%;
            }
        }
    }

    &.govTeams-UserAccess-modal {
        .ms-Dialog-main {
            @media (min-width: 768px) {
                min-width: 670px;
            }

            .ms-Dialog-header {
                .ms-Dialog-title {
                    @include ms-font-su;
                    line-height: 1.1;
                    margin-bottom: 20px !important;
                }
            }

            .govTeams-fieldVerify {
                margin-top: 20px;
            }

            .ms-Dialog-action {
                &:first-child .govTeams-actionButton {
                    border-right: 1px solid $ms-color-neutralTertiary;
                    padding-right: 10px !important;
                }

                .govTeams-actionButton {
                    height: auto;
                }
            }
        }
    }

    .formFooter {
        text-align: right;
        margin-bottom: 0;
    }
}

.govTeams-dialog-results {
    .ms-Dialog-header {
        .ms-Dialog-title {
            margin-bottom: 0 !important;
            padding-top: 50px;
        }
    }

    .ms-Dialog-inner {
        @media screen and (min-width: $ms-screen-max-md) {
            padding-left: 50px;
            padding-right: 50px;
            padding-bottom: 50px;
        }
    }

    .h1 {
        margin-top: 0;
    }

    p {
        @include ms-fontSize-mPlus;
    }

    .formFooter {
        margin-bottom: 0;
    }
}
