/**
 * Primary page layout rules 
 */
$spacing: 50px;

.screenReaderOnly {
  @include ms-screenReaderOnly;
}

// style for IE to place scrollbar outside of content so it doesn't overlay content
html {
  height: 100%;
  -ms-overflow-style: scrollbar;

  body,
  #root {
    height: 100%;
  }

  #root > .ms-Fabric {
    height: 100%;
  }

  input[type='date'] {
    line-height: 1 !important; // fix for edge where line height is wrong
  }
}

.govTeams-App-wrapper {
  background-color: $ms-color-neutralLighterAlt;
  height: 100%;

  .govTeams-header {
    width: 100%;
    position: fixed;
    top: 0;
    z-index: 10;
  }

  .govTeams-pageContainer {
    background-color: $ms-color-neutralLighterAlt;
    min-height: 95vh;
    margin-top: 0;
    margin-bottom: -40px;
    padding-top: 50px;
    padding-bottom: 130px;

    @media (max-width: $ms-screen-max-md) {
      margin-bottom: -80px;
      padding-bottom: 80px;
    }

    // &.govTeams-pageCommunity,
    &.govTeams-pageInviteMembers,
    &.govTeams-pageProfile,
    &.govTeams-pageReactivate,
    // &.govTeams-pageMembers,
    &.govTeams-pageReserved,
    &.govTeams-pageBlocked,
    &.govTeams-pageLicencePending,
    &.govTeams-pageLicenceBlocked {
      background-color: $ms-color-white;
    }

    // &:after {
    //   content: '';
    //   height: 40px;
    //   display: block;

    //   @media (max-width: $ms-screen-max-md) {
    //     height: 80px;
    //   }
    // }

    .govTeams-content {
      padding-left: $spacing;
      padding-right: $spacing;
      padding-bottom: 50px;

      @media (max-width: $ms-screen-max-md) {
        padding-left: $spacing * 0.5;
        padding-right: $spacing * 0.5;
      }
    }

    .govTeams-errorContent {
      padding-top: $spacing;
    }
  }

  .govTeams-Footer {
    height: 40px;
    margin: 0;

    @media (max-width: $ms-screen-max-md) {
      height: 80px;
    }
  }
}

.govTeams-container {
  margin-left: auto;
  margin-right: auto;

  @media (min-width: $ms-screen-min-xxxl) {
  }

  @media (min-width: $ms-screen-min-xxl) {
  }

  @media (min-width: $ms-screen-min-xl) {
    width: $ms-screen-min-xl;
  }

  @media (min-width: $ms-screen-min-lg) {
  }

  @media (min-width: $ms-screen-min-md) {
  }

  @media (min-width: $ms-screen-min-sm) {
  }
}

/**
 * Overrides for Office Fabric UI modals
 */
.govTeams-progress-modal {
  .ms-Dialog-main {
    padding: 40px;
  }
}

/**
 * Page with two equal width columns
 */
.pageLayout-twoCol {
  @include ms-Grid;
  padding-top: 20px;

  /**
   * Back button
   */
  .govTeams-actionButton {
    margin-bottom: 20px;
  }

  /**
   * Two column layout for Orgs
   */
  .pageLayout-row {
    @include ms-Grid-row;
    margin: 0 -20px;

    .pageLayout-col {
      @include ms-Grid-col;
      @include ms-sm12;
      @include ms-md12;
      @include ms-lg12;
      @include ms-xl6;
      padding-left: 20px;
      padding-right: 20px;
    }
  }

  .page-header {
    color: $ms-color-white;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 0;
    margin-bottom: 0;
    padding: 10px 30px;
    border-radius: 8px 8px 0 0;

    h1 {
      @include ms-fontWeight-semilight;
      word-break: break-word;
      margin-bottom: 0;
      padding-right: 90px;
    }

    .page-header-status {
      display: flex;
      align-items: center;

      .communityTypeBadge {
        @include ms-fontSize-s;
        @include ms-fontWeight-semibold;
        text-align: center;
        background-color: $ms-color-white;
        width: 8rem;
        line-height: 20px;
        border-radius: 4px;
        padding: 0 8px 2px;
      }

      .sponsored {
        @include ms-fontWeight-semibold;
        color: $ms-color-white;
        font-size: 28px;
        margin-left: 20px;
      }
    }
  }

  .page-cell {
    word-break: break-word;
    background: $ms-color-white;
    padding: 30px;

    & + .page-cell {
      padding-top: 0;
    }

    hr {
      margin-top: 30px;
      margin-bottom: 30px;
    }

    // h3 {
    //   margin-top: 40px;
    // }

    .page-cellHeader {
      margin-bottom: 20px;

      h2 {
        margin-bottom: 0;
      }

      .ms-Button--icon {
        height: auto;

        &:hover {
          background-color: transparent;
        }

        .ms-Button-icon {
          color: $ms-color-themePrimary;
        }
      }

      .ms-TooltipHost {
        float: right;
      }
    }

    .button-see-all {
      @include ms-fontWeight-semibold;
      color: $ms-color-themePrimary;
      margin-top: 20px;
      padding: 0;

      i {
        margin-left: 8px;
        vertical-align: -2px;
      }
    }
  }

  .govTeams-communityTools {
    ul li {
      @media (min-width: $ms-screen-min-lg) {
        width: 50%;
        float: left;
      }
    }
  }
}

/**
 * add spacing around elements
 */
.spacing-bottom {
  margin-bottom: $spacing;
}

/**
 * Collapsible sections
 */
.govTeams-collapsibleSection {
  border-top: 1px solid $ms-color-neutralLight;
  margin-top: 30px;
  padding-top: 30px;

  .collapsibleHeader {
    padding-left: 30px;
    position: relative;
    cursor: pointer;

    i {
      @include ms-fontSize-mPlus;
      position: absolute;
      top: 0;
      left: 0;
    }
  }

  .collapsibleContent {
    padding-top: 20px;
    padding-left: 30px;
  }
}

.govTeams-messageBar.ms-MessageBar {
  .ms-MessageBar-innerText {
    font-size: $ms-font-size-m-plus;
    line-height: 1.4;
  }

  i {
    font-size: $ms-font-size-xl;
    margin-top: 4px;
  }

  &.ms-MessageBar--success {
    background-color: #dff6dd;
  }

  .ms-Link {
    @include ms-fontWeight-semibold;
    font-size: $ms-font-size-m-plus;
  }
}

.table-requestError {
  th {
    padding-right: 8px;
  }

  th,
  td {
    @include ms-fontSize-mPlus;
    padding-bottom: 8px;
  }
}

.govTeams-Callout-body {
  padding: 20px 30px;

  h3 {
    margin-top: 0;
  }

  p:last-child {
    margin-bottom: 0;
  }
}

.govTeams-pageLicenceBlocked,
.govTeams-pageLicencePending {
  h1 {
    margin-top: 70px;
  }
}

.govTeams-CommandBar {
  .ms-CommandBar {
    padding-right: 0;
  }

  .ms-CommandBar-secondaryCommand {
    .ms-Button {
      padding-left: 16px;
      padding-right: 16px;

      &:not(.is-disabled) {
        color: $ms-color-themePrimary;
      }
    }

    .ms-OverflowSet-item:last-child .ms-Button:not(.is-disabled) {
      color: $ms-color-white;
      background-color: $ms-color-themePrimary;

      i {
        color: $ms-color-white;
      }
    }
  }
}

.govTeams-textLayout {
  padding-right: 50px;
  padding-bottom: 0;
  padding-top: 40px;

  @media (min-width: $ms-screen-min-xl) {
    max-width: $ms-screen-min-xl;
    padding-left: 205px;
  }
}

.display-flex {
  display: flex;
}
.display-flex-wrap {
  display: flex;
  flex-wrap: wrap;
}
.display-flex-items {
}

@media only screen and (max-width: 1024px) {
  .display-flex-items:nth-child(1) {
    order: 2;
  }
  .display-flex-items:nth-child(2) {
    order: 1;
  }
}

.align-center {
  margin-left: auto;
  margin-right: auto;
}
.one-line-left-right {
  display: flex;
  justify-content: space-between;
}
.m-05 {
  margin: 0.5em;
}
.m-1 {
  margin: 1em;
}
.m-2 {
  margin: 2em;
}
.mx-05 {
  margin-left: 0.5em;
  margin-right: 0.5em;
}
.mx-1 {
  margin-left: 1em;
  margin-right: 1em;
}
.mx-2 {
  margin-left: 2em;
  margin-right: 2em;
}
.mx-3 {
  margin-left: 3em;
  margin-right: 3em;
}
.my-05 {
  margin-top: 0.5em;
  margin-bottom: 0.5em;
}
.my-1 {
  margin-top: 1em;
  margin-bottom: 1em;
}
.my-2 {
  margin-top: 2em;
  margin-bottom: 2em;
}
.my-3 {
  margin-top: 3em;
  margin-bottom: 3em;
}
.ml-05 {
  margin-left: 0.5em;
}
.ml-1 {
  margin-left: 1em;
}
.mr-05 {
  margin-right: 0.5em;
}
.mr-1 {
  margin-right: 1em;
}
.mt-05 {
  margin-top: 0.5em;
}
.mt-1 {
  margin-top: 1em;
}
.px-05 {
  padding-left: 0.5em;
  padding-right: 0.5em;
}
.px-1 {
  padding-left: 1em;
  padding-right: 1em;
}
.px-2 {
  padding-left: 2em;
  padding-right: 2em;
}
.px-3 {
  padding-left: 3em;
  padding-right: 3em;
}
.py-5p {
  padding-top: 5px;
  padding-bottom: 5px;
}
.py-10p {
  padding-top: 10px;
  padding-bottom: 10px;
}
.py-05 {
  padding-top: 0.5em;
  padding-bottom: 0.5em;
}
.py-1 {
  padding-top: 1em;
  padding-bottom: 1em;
}
.py-2 {
  padding-top: 2em;
  padding-bottom: 2em;
}
.py-3 {
  padding-top: 3em;
  padding-bottom: 3em;
}
.pl-05 {
  padding-left: 0.5em;
}
.pl-1 {
  padding-left: 1em;
}
.pr-05 {
  padding-right: 0.5em;
}
.pr-1 {
  padding-right: 1em;
}
.govTeams-announcement-padding {
  padding-top: 8px;
  padding-bottom: 5px;
}
.buildNumber-container {
  display: flex;
  min-height: 50px;
  text-align: right;
  flex-direction: column-reverse;

  .buildNumber-text {
    font-size: 12px;
    color: #333333;
    display: inline-block;
    align-self: flex-end;
  }
}

.tableComponentList {
  max-height: 48vh;
  overflow: auto;
}