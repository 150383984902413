.govTeams-registration {
    height: 100%;

    .govTeams-App-wrapper {
        height: 100%;
    }

    .govTeams-header {
        position: relative;
    }

    .govTeams-pageContainer {
        background-color: $ms-color-neutralLighterAlt;
        min-height: 100%;
        margin-top: -50px;
        margin-bottom: -40px;
        padding-top: 50px;
        padding-bottom: 40px;

        &:after {
            content: '';
            height: 40px;
            display: block;
        }
    }

    .govTeams-Footer {
        height: 40px;
        margin: 0;
    }

    .formNav-Registration,
    .formBody-Registration {
        @media screen and (min-width: $ms-screen-min-xl) {
            float: left;
        }
    }

    .formNav-Registration {
        .h2 {
            margin-top: 60px !important;
        }
    }

    .form-control {
        border: 1px solid #a6a6a6;
        margin: 0 0 20px !important;
        border-radius: 0;
    }

    .form-control:focus {
        border-color: #2b88d8;
        box-shadow: none;
    }

    .progressBar {
        margin: 0 0 20px !important;
    }

    .input-validation-error {
        border-color: #107c10;
    }

    .field-validation-error {
        background-color: #fed9cc;
        display: block;
        margin-bottom: 20px;
        padding: 16px;
    }

    .termsandconditions {
        margin-left: 10px;
    }

    h1 {
        font-weight: 400;
        font-size: 36px;
        margin-top: 0;
        margin-bottom: 20px;
    }

    a.btn {
        padding-top: 5px !important;
    }

    .btn,
    .btn.btn-primary[disabled] {
        font-size: 14px;
        font-weight: 600;
        color: inherit;
        box-sizing: border-box;
        display: inline-block;
        text-align: center;
        cursor: pointer;
        vertical-align: top;
        padding-top: 0px;
        padding-right: 16px;
        padding-bottom: 0px;
        padding-left: 16px;
        min-width: 80px;
        height: 32px;
        background-color: rgb(244, 244, 244);
        user-select: none;
        outline: transparent;
        border-width: 1px;
        border-style: solid;
        border-color: transparent;
        border-image: initial;
        text-decoration: none;
        text-shadow: none;
        border-radius: 0px;
        box-shadow: none;
    }

    .btn:hover {
        background-color: rgb(234, 234, 234);
        border-color: transparent;
    }

    .btn-primary {
        color: rgb(255, 255, 255);
        background: rgb(0, 120, 212);
    }

    .btn-primary:hover {
        background: rgb(16, 110, 190);
    }

    .btn-next {
        padding-right: 8px;

        &:after {
            @include ms-Icon;
            @include ms-Icon--ChevronRightSmall;
            font-size: .8em;
            margin-left: 8px;
        }
    }

    .nav-pills > li {
        margin-left: 0 !important;
        text-align: right;

        @media screen and (min-width: $ms-screen-min-xl) {
            float: none;
            margin-bottom: 36px;
        }

        &:last-child {
            margin-bottom: 0;
        }
    }

    .nav-pills > li > a,
    .nav-pills > li > a:focus,
    .nav-pills > li > a:hover {
        border-radius: 0;
        display: inline-block !important;
        padding-left: 24px !important;
        width: auto !important;
    }

    .govTeams-pivot {
        margin-top: 28px;

        @media screen and (min-width: $ms-screen-min-xl) {
            margin-top: 120px;
        }
    }

    .govTeams-pivot .ms-Pivot-link {
        color: $ms-color-neutralPrimary !important;
        border-left: 2px solid transparent;
        font-weight: 300 !important;
    }

    .govTeams-pivot .active .ms-Pivot-link {
        color: $ms-color-neutralPrimary !important;
        border-color: $ms-color-neutralPrimary;
        font-weight: 600 !important;
    }

    .tooltipHost {
        display: inline-block;
    }

    .tooltipHost .ms-Icon--Info {
        color: #2b88d8;
        margin-left: 0.5em;
    }

    .tooltip {
        box-shadow: rgba(0, 0, 0, 0.4) 0px 0px 5px 0px;
        border-width: 1px;
        border-style: solid;
        border-color: rgb(234, 234, 234);
        outline: transparent;
        opacity: 1 !important;
        padding: 0 !important;
        box-sizing: border-box;
    }

    .tooltip .tooltip-arrow {
        background-color: rgb(255, 255, 255);
        box-shadow: inherit;
        box-sizing: border-box;
        transform: rotate(45deg);
        height: 12px;
        width: 12px;
        position: absolute;
        top: 50% !important;
        left: -6px !important;
        margin-top: -6px !important;
    }

    .tooltip .tooltip-inner {
        color: #333;
        text-align: left;
        background: #fff;
        padding: 8px;
        border-radius: 0;
        position: relative;
        z-index: 100;
    }

    .popover {
        font-family: inherit !important;
        color: #fff;
        background: #2b88d8;
        width: 364px;
        border: none;
        border-radius: 0;
        box-shadow: none;
    }

    .popover-arrow {
        border-right-color: transparent;
    }

    .popover-title {
        font-size: 28px;
        font-weight: 100;
        padding: 20px;
        background: transparent;
        border-radius: 0;
        border-bottom: none;
    }

    .popover.top > .arrow:after {
        border-top-color: #2b88d8;
    }

    .popover.right > .arrow:after {
        border-right-color: #2b88d8;
    }

    .popover-content {
        padding: 20px;
    }

    .label-required {
        color: #a80000;
    }

    label {
        font-weight: normal;
    }

    .termsandconditions {
        label {
            position: relative;
            z-index: 1;
            margin-left: -18px;
            padding-left: 18px;
        }

        input {
            position: relative;
            z-index: 2;
        }

        input[disabled] {
            z-index: 0;
        }
    }

    .usernameNote {
        font-size: small;
        font-style: italic;
    }
    /*label.password {
        font-size: 20px;
    }*/
    /*.password-heading {
        font-size:18px;
        font-family: 'Segoe UI SemiBold' 
    }*/
    #Password {
        border-bottom: 3px solid #f44336;

        &.valid {
            border-bottom-color: #4caf50;
        }
    }

    .confirmrequirementsSection {
        height: 20px;
    }

    .password-alert {
        margin: 20px 20px;
    }

    .password-match {
        margin: 20px 20px;
    }

    .label {
        width: 100%;
    }
    /*.alert {
            display: none;
        }*/
    .requirements {
        list-style-type: none;
    }

    .confirmrequirements {
        list-style-type: none;
    }

    .green-text {
        color: #4caf50 !important;
    }

    .red-text {
        color: #f44336 !important;
    }

    .confirmrequirements .fas,
    .requirements .fas {
        margin-left: -18px;
    }

    .confirmrequirements {
        display: none;

        &.wrong {
            display: block;

            .fas {
                display: inline-block;
            }
        }
    }

    .wrong .fa-check {
        display: none;
    }

    .good .fa-times {
        display: none;
    }

    .divTextArea {
        padding: 2px;
        width: 85%;
        height: 450px;
        border: 1px solid #a6a6a6;
        overflow: scroll;
        overflow-y: scroll;
        overflow-x: hidden;
    }

    #loading-div-background {
        display: none;
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
    }

    #loading-div-background:before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        background: #fff;
        opacity: 0.5;
        width: 100%;
        height: 100%;
        z-index: 50;
    }

    #loading-div {
        width: 300px;
        height: 150px;
        font-size: 16px;
        color: #2b88d8;
        background-color: #fff;
        box-shadow: rgba(0, 0, 0, 0.4) 0px 0px 5px 0px;
        text-align: center;
        position: absolute;
        left: 50%;
        top: 50%;
        margin-left: -150px;
        margin-top: -100px;
        -webkit-border-radius: 5px;
        -moz-border-radius: 5px;
        border-radius: 0;
        /*behavior: url('/css/pie/PIE.htc'); // Commented it to stop error */
        /* HANDLES IE */
        z-index: 100;
    }

    @media screen and (max-width: 1023px) {
        .popover {
            margin-left: -286px !important;
        }

        .popover > .arrow {
            border-right-color: transparent !important;
            border-left-color: rgba(0, 0, 0, 0.25) !important;
            border-left-width: 11px !important;
            border-right-width: 0 !important;
            left: auto !important;
            right: -11px !important;

            &:after {
                border-right-color: transparent !important;
                border-left-color: #2b88d8 !important;
                border-left-width: 10px !important;
                border-right-width: 0 !important;
                left: -11px !important;
            }
        }
    }
}
