.govTeams-reactivate {
  padding-bottom: 40px;
  
  h1 {
    margin-top: 60px;
    margin-bottom: 0;
  }

  p {
    font-size: $ms-font-size-m-plus;
    margin-bottom: 20px;
  }

  .govTeams-userAccess,
  .collapsibleContent { 
    padding-right: 15%;
  }

  .govTeams-fieldVerify {
    margin-bottom: 20px;
  }

  .is-required .ms-TextField-fieldGroup:after {
    content: "";
  }
}
