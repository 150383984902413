

.govTeams-carousel {
  margin-bottom: 30px;
  padding: 0;
  overflow: hidden;

  .carousel {
    --slide-spacing: 1rem;
    --slide-size: 100%;
    --slide-height: 300px;
  }
  .carousel__viewport {
    overflow: hidden;
    position: relative;
  }
  .carousel__container {
    backface-visibility: hidden;
    display: flex;
    touch-action: pan-y;
    margin-left: calc(var(--slide-spacing) * -1);
  }
  .carousel__slide {
    flex: 0 0 var(--slide-size);
    min-width: 0;
    padding-left: var(--slide-spacing);
    position: relative;
  }
  .carousel__overlay {
    width: 100%;
    height: 100%;

    display:inline-block;
    background: linear-gradient(rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.9));
  }
  .carousel__slide__img {
    position: relative;
    z-index:-1;
    display: block;
    height: var(--slide-height);
    width: 100%;
    object-fit: cover;
  }

  .carousel__slide__title {
    z-index: 10;
    position: absolute;
    left: 50px;
    bottom: 40px;
    pointer-events: none;
    color: white;
    padding: 0 5px;
    margin: 0 15px;
    overflow: hidden;

    h2 {
      font-size: 26px;
      margin-bottom: 10px;
      font-weight: normal;
      text-align: left;
      color: white;
    }
    p {
      text-align: left;
      font-weight: normal;
      font-size: 14px;
    }
  }

  .link {
    cursor: pointer !important;
  }

  .carousel__slide__number {
    width: 4.6rem;
    height: 4.6rem;
    z-index: 100;
    position: absolute;
    top: 40px;
    right: 40px;
    border-radius: 50%;
    background-color: rgba($ms-color-themePrimary, 0.25);
    line-height: 4.6rem;
    font-weight: 900;
    text-align: center;
    pointer-events: none;

    span {
      color: rgba(white, 0.25);
      background-clip: text;
      -webkit-background-clip: text;
      font-size: 1.6rem;
      display: block;
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
    }
  }

  .carousel__dots {
    z-index: 5;
    bottom: 1.6rem;
    position: absolute;
    left: 0;
    right: 0;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .carousel__dot {
    -webkit-appearance: none;
    touch-action: manipulation;
    text-decoration: none;
    cursor: pointer;
    padding: 0;
    height: 1em;
    width: 1em;
    background-color: gray;
    border: 1px solid gray;
    border-radius: 50%;
    display: flex;
    align-items: center;
    margin-right: 0.75rem;
    margin-left: 0.75rem;

    &:after {
      border-radius: 0.2rem;
      content: '';
    }
  }
  .carousel__dot--selected:after {
    height: 1em;
    width: 1em;
    border-radius: 50%;
    background: linear-gradient(
                    45deg,
                    $ms-color-themePrimary,
                    $ms-color-themeDark
    );
  }

}
