/**
 * Community managment page
 * Extend layout for two column pages
 */
.communityManagement-layout {
  padding-top: 20px;

  .govTeams-actionButton {
    margin-bottom: 20px;
  }

  .page-header {
    color: $ms-color-white;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 0;
    margin-bottom: 0;
    padding: 20px 30px;
    border-radius: 8px 8px 0 0;

    .govTeams-pageMembers & {
      justify-content: flex-start;

      .communityTypeBadge {
        white-space: nowrap;
        margin-left: 24px;
      }
    }

    h1 {
      @include ms-fontWeight-semibold;
      font-size: 26px;
      word-break: break-word;
      margin-bottom: 0;
    }

    .page-header-status {
      display: flex;
      align-items: center;

      .communityTypeBadge {
        @include ms-fontSize-s;
        @include ms-fontWeight-semibold;
        text-align: center;
        background-color: $ms-color-white;
        width: 10rem;
        line-height: 20px;
        border-radius: 4px;
        padding: 0 8px 2px;
      }

      .sponsored {
        @include ms-fontWeight-semibold;
        color: $ms-color-white;
        font-size: 28px;
        margin-left: 20px;
      }
    }
  }

  .page-cell {
    word-break: break-word;
    background: $ms-color-white;
    padding: 20px 30px 30px;

    & + .page-cell {
      padding-top: 0;
    }

    hr {
      margin-top: 30px;
      margin-bottom: 30px;
    }

    h3 {
      margin-top: 0;
    }

    h2 {
      color: $govTeams-blueDark;
      border-bottom: 3px solid $govTeams-blueDark;
      padding-bottom: 20px;
      margin-bottom: 20px;
    }

    .page-cellHeader {
      // margin-bottom: 20px;

      .ms-Button--icon {
        height: auto;

        &:hover {
          background-color: transparent;
        }

        .ms-Button-icon {
          color: $ms-color-themePrimary;
        }
      }

      .ms-TooltipHost {
        float: right;
      }
    }

    .button-see-all {
      @include ms-fontWeight-semibold;
      color: $ms-color-themePrimary;
      margin-top: 20px;
      padding: 0;

      i {
        margin-left: 8px;
        vertical-align: -2px;
      }
    }
  }

  .govTeams-communityActions {
    display: flex;
    margin: 0;
    padding: 0;
    flex-wrap: wrap;

    > div {
      width: 50%;
      margin-bottom: 20px;
    }
  }

  .section-twoCol {
    @media (min-width: $ms-screen-min-lg) {
      display: flex;
    }

    .section-twoCol-left {
      flex: 5;
      display: flex;
      flex-direction: column;

      @media (min-width: $ms-screen-min-xxl) {
        flex: 3;
      }
    }

    .section-twoCol-right {
      flex: 5;
      margin-top: 40px;
      display: flex;
      flex-direction: column;

      @media (min-width: $ms-screen-min-lg) {
        margin-top: 0;
        margin-left: 40px;
      }
    }
  }

  .page-cell {
    border-radius: 8px;
    display: flex;
    flex-grow: 1;
    flex-direction: column;
    justify-content: space-between;
  }
}

.page-cell {
  .communityPage-owners {
  
    @media (min-width: $ms-screen-min-xl) {
      display: flex;
      flex-wrap: wrap;
      margin-top: -20px;
    }
  }

  .communityOwners-list {
    @media (min-width: $ms-screen-min-xl) {
      .ms-List-page {
        display: flex;
        flex-wrap: wrap;
        margin-left: -20px;
        margin-right: -20px;

        .ms-List-cell {
          width: 50%;
          padding-left: 20px;
          padding-right: 20px;
        }
      }
    }
  }
}

.page-cell .tags {
  margin-top: 18px;

  .tag {
    margin-top: 12px;
  }
}


.tag {
  @include ms-fontWeight-regular;
  font-size: inherit;
  color: inherit;
  background-color: $ms-color-neutralLight;
  line-height: 1;
  display: inline-block;
  padding: 4px 16px;
  margin-right: 16px;
  border-radius: 6px;
}

.pageLayout-threeCol {
  .pageLayout-col {
    margin-bottom: 40px;

    &:last-child {
      margin-bottom: 0;
    }
  }

  @media (min-width: $ms-screen-min-xl) {
    display: flex;
    margin-left: -20px;
    margin-right: -20px;

    .pageLayout-col {
      width: 33.343%;
      padding-left: 20px;
      padding-right: 20px;
      margin-bottom: 0;
    }
  }
}

.memberItem {
  @include ms-clearfix;
  margin-top: 20px;
  position: relative;

  .ms-TooltipHost {
    cursor: auto;
  }

  .memberPersona {
    .member-status {
      @include ms-fontWeight-semilight;
    }

    .ms-Persona {
      align-items: normal;
      height: auto !important;
    }

    .ms-Persona-coin {
      width: 40px;
      margin-top: 4px;
    }

    .ms-Persona-primaryText {
      @include ms-fontSize-l;
      @include ms-fontWeight-regular;
    }

    .ms-Persona-initials {
      @include ms-fontWeight-semibold;
      width: 40px;
      height: 40px;
      font-size: 14px;
      line-height: 40px;
    }

    .memberPersona-meta-primary {
      @include ms-fontSize-m;
      color: $ms-color-neutralSecondary;
    }

    .ms-Persona-secondaryText,
    .ms-Persona-tertiaryText,
    .memberPersona-meta-secondary {
      @include ms-fontSize-m;
      color: $ms-color-neutralSecondary;
    }

    .sponsoredBadge {
      @include ms-fontSize-s;
      @include ms-fontWeight-semibold;
      color: $ms-color-white;
      background-color: $ms-color-blue;
      display: inline-block;
      line-height: 20px;
      border-radius: 20px;
      padding: 0 8px 2px;
      //margin-left: 0px;
      vertical-align: 2px;
    }

    @media (min-width: $ms-screen-min-lg) {
      padding-right: 90px;
    }
  }

  .memberTools {
    border-bottom: 1px solid $ms-color-neutralLight;
    padding: 5px 0 10px;

    @media (min-width: $ms-screen-min-lg) {
      position: absolute;
      top: 0;
      right: 0;
      border: none;
      margin: 0;
      padding: 0;
    }

    .ms-TooltipHost {
      height: 30px;
      border-left: 1px solid $ms-color-neutralLight;
      display: inline-block;
      margin-left: 4px;
      padding-left: 4px;

      &:first-child {
        border-left: none;
        margin-left: 0;
        padding-left: 0;
      }
    }

    .ms-Button--icon {
      color: inherit;

      &:hover {
        text-decoration: none;
      }
    }

    .ms-TooltipHost:first-child .ms-Button--icon {
      border-left: none;
    }

    .memberItem-accept i {
      font-size: 20px;
      color: $ms-color-green;
    }

    .memberItem-remove i {
      font-size: 20px;
      color: $ms-color-red;
    }
  }
}

.memberItem-requestMessage {
  background-color: $ms-color-neutralLighterAlt;
  margin-top: 10px;
  margin-left: 56px;
  padding: 20px;
}

.ms-List-surface {
  .ms-List-cell:last-child {
    .memberTools {
      border-bottom: none;
    }
  }
}

.communityMembers {
  .communityMembers-header {
    position: relative;
    color: $ms-color-white;
    margin-bottom: 20px;
    padding: 10px 20px;

    h1 {
      word-break: break-word;
      margin-bottom: 0;
      padding-right: 60px;
    }

    .communityMembers-header-tools {
      @include ms-fontWeight-regular;
      font-size: 20px;
      position: absolute;
      top: 5px;
      right: 20px;
      padding: 9px 0;

      i {
        margin-right: 10px;
      }
    }
  }

  .button-invite {
    margin-bottom: 10px;
  }

  .memberItem-remove i {
    width: 20px;
    height: 20px;
    font-size: 14px;
    color: $ms-color-white;
    background-color: $ms-color-red;
    line-height: 1.4;
    text-align: center;
    border-radius: 50%;
    border: 1px solid $ms-color-red;
  }
}

.sponsoredBadge {
  @include ms-fontSize-s;
  @include ms-fontWeight-semibold;
  background-color: $ms-color-themePrimary;
  color: $ms-color-white;
  line-height: 20px;
  border-radius: 20px;
  margin-left: 8px;
  padding: 0 8px 2px;
  vertical-align: 3px;
}

.rdw-editor-wrapper {
  background-color: $ms-color-white;
  border: 1px solid #a6a6a6;
}

.rdw-editor-main {
  padding-right: 12px;
  padding-bottom: 0px;
  padding-left: 12px;
}
